import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Avatar,
    Button,
    Card,
    Empty,
    Image,
    Input,
    Message,
    Space,
    Spin,
    Tag,
    Tooltip,
    Typography,
    Upload,
} from "@arco-design/web-react";
import {
    IconCopy,
    IconDelete,
    IconFile,
    IconFileImage,
    IconRefresh,
    IconSend,
    IconWechat,
} from "@arco-design/web-react/icon";
import {
    postRunByThreadBotCloud,
} from "../../../api/openaiAPI";
import LaunchModal from "./components/ChatTest/LaunchModal";
import {
    getLarkTemporaryDownloadURLs,
} from "../../../api/normalApi";
import {formatTimestampToDateTime} from "../../../utils/format";
import OpenAI from "openai";

const {Text} = Typography;

const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
});

const ChatTest = ({
                      threadId,
                      setThreadId,
                      draftInstructions,
                      setDraftInstructions,
                      roleInstructions,
                      tableData,
                      selectedEmployee,
                      chatMode,
                      chatBackground,
                      handleBackgroundChange,
                      maxSeats
                  }) => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [imageUrls, setImageUrls] = useState({}); // 存储图片的URL
    const [file, setFile] = React.useState();
    const [isUserScrolling, setIsUserScrolling] = useState(false); //是否允许气泡content滑动
    const contentRef = useRef(null);
    const salesMode = useRef(0);
    const disableValue = useRef(false); //是否允许输入框输入
    const [isLoadingChat, setIsLoadingChat] = useState(false); //是否在加载对话
    const timeoutRef = useRef(null); // 添加useRef来存储定时器ID
    const [larkImageUrls, setLarkImageUrls] = useState({});
    // 存储最后一条消息
    const lastMessage = useRef(null);

    useEffect(() => {
        let newUrls = [];
        messages.forEach(msg => {
            if (msg.content && Array.isArray(msg.content) && msg.content.length > 0) {
                msg.content.forEach(contentItem => {
                    if (contentItem.type === 'text' && contentItem.text.value) {
                        // 使用带 g 标志的正则表达式
                        const regex = /(https:\/\/open\.feishu\.cn\/open-apis\/drive\/v1\/medias\/([^/]+)\/download)/g;
                        let match;
                        // 使用 while 循环获取所有匹配项
                        while ((match = regex.exec(contentItem.text.value)) !== null) {
                            const fileToken = match[2];
                            if (!newUrls.includes(fileToken)) {  // 避免重复
                                newUrls.push(fileToken);
                            }
                        }
                    }
                });
            }
        });
        if (newUrls.length > 0) {
            handleDownloadPic(newUrls)
        }
    }, [messages]);

    const handleDownloadPic = async (fileTokens) => {
        try {
            // console.log('Downloading files for tokens:', fileTokens);
            const res = await getLarkTemporaryDownloadURLs(fileTokens);
            const tmpUrls = res.data.download_urls;
            // console.log('API Response:', res.data); // 添加完整响应日志

            if (!tmpUrls || tmpUrls.length === 0) {
                console.error('No URLs returned from API');
                return;
            }

            if (tmpUrls.length !== fileTokens.length) {
                console.warn(`Expected ${fileTokens.length} URLs but got ${tmpUrls.length}`);
            }

            // 确保所有 fileToken 都有对应的 URL
            const tmpFiles = {};
            fileTokens.forEach((token, index) => {
                const matchingUrl = tmpUrls.find(url => url.file_token === token);
                if (matchingUrl) {
                    tmpFiles[token] = matchingUrl.tmp_download_url;
                } else {
                    console.warn(`No URL found for token: ${token}`);
                    // 可以选择重试这个特定的 token
                    retryDownload(token);
                }
            });

            // console.log('Processed URLs:', tmpFiles);

            setLarkImageUrls(prevUrls => {
                const newUrls = {
                    ...prevUrls,
                    ...tmpFiles
                };
                // console.log('Final image URLs state:', newUrls);
                return newUrls;
            });
        } catch (error) {
            console.error("Error downloading file:", error);
            Message.error("下载文件时出错，请稍后再试。");
        }
    };

// 添加单个文件的重试逻辑
    const retryDownload = async (fileToken) => {
        try {
            const res = await getLarkTemporaryDownloadURLs([fileToken]);
            if (res.data.download_urls && res.data.download_urls.length > 0) {
                setLarkImageUrls(prevUrls => ({
                    ...prevUrls,
                    [fileToken]: res.data.download_urls[0].tmp_download_url
                }));
            }
        } catch (error) {
            console.error(`Retry download failed for token ${fileToken}:`, error);
        }
    };

    const findFileName = (tableData, targetFileToken) => {
        // 如果 tableData 不存在，返回未知文件名
        if (!tableData) return '未知文件名';

        let fileName = '未知文件名';

        // 遍历所有表
        Object.entries(tableData).forEach(([tableName, tableContent]) => {
            // 检查是否有 records
            if (tableContent?.records) {
                // 遍历所有记录
                tableContent.records.forEach(record => {
                    // 遍历记录中的所有字段
                    Object.entries(record).forEach(([fieldName, fieldValue]) => {
                        // 检查是否是附件字段（数组类型）
                        if (Array.isArray(fieldValue)) {
                            // 查找匹配的文件
                            const attachment = fieldValue.find(item =>
                                item.file_token === targetFileToken
                            );
                            if (attachment?.name) {
                                fileName = attachment.name;
                            }
                        }
                    });
                });
            }
        });

        return fileName;
    };

    const msgMap = {
        normal: (sentence, tableData, msg) => {
            // 同一套文字样式
            const commonStyle = {
                background: msg.role === "user" ? "#f7f7fa" : "#366ef4",
                color: msg.role === "user" ? "#000000" : "#ffffff",
                padding: "10px",
                borderRadius: "8px",
                display: "inline-block",
                // 其余你想要的公共样式都放这里
            };

            // 如果你想在这里顺便做"用户的句子保留原标点、助手的句子去掉句尾标点"之类的逻辑：
            const displayText =
                msg.role === "user"
                    ? sentence
                    : sentence.replace(/[？?！!。，,；;、]+$/g, "");

            return (
                <Text style={commonStyle}>
                    {displayText}
                </Text>
            );
        },

        // file 分支中
        file: (sentence, tableData, msg) => {
            const commonStyle = {
                background: msg.role === "user" ? "#f7f7fa" : "#366ef4",
                color: msg.role === "user" ? "#000000" : "#ffffff",
                padding: "10px",
                borderRadius: "8px",
                display: "inline-block",
            };

            const elements = [];
            let lastIndex = 0;
            let match;

            // 重置正则表达式的lastIndex
            larkFileRegex.lastIndex = 0;

            while ((match = larkFileRegex.exec(sentence)) !== null) {
                const fileLink = match[0];
                const fileToken = match[2];
                const linkIndex = match.index;

                // 添加链接前的文本
                const beforeText = sentence.slice(lastIndex, linkIndex);
                if (beforeText.trim()) {
                    elements.push(
                        <Text style={commonStyle} key={`text-${lastIndex}`}>
                            {beforeText}
                        </Text>
                    );
                }

                // 使用新的查找文件名函数
                const fileName = findFileName(tableData, fileToken);

                // 判断是否为图片文件
                if (isImage(fileToken, fileName)) {
                    elements.push(
                        <Image
                            key={`image-${fileToken}`}
                            src={larkImageUrls[fileToken]}
                            alt="图片文件"
                            width={300}
                            onError={(e) => {
                                console.error('Image load error:', e);
                            }}
                            onLoad={() => {
                                console.log('Image loaded successfully');
                            }}
                        />
                    );
                } else {
                    elements.push(
                        <Card
                            key={`card-${fileToken}`}
                            hoverable
                            style={{
                                width: "100%",
                                height: 50,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Space align="center">
                                <IconFile style={{fontSize: 30, color: "#8bc34a"}}/>
                                <div style={{fontSize: 16, fontWeight: "bold"}}>{fileName}</div>
                                <Button
                                    type="text"
                                    style={{color: "#165DFF"}}
                                    onClick={() => handleDownloadFile(fileToken, fileName)}
                                >
                                    下载
                                </Button>
                            </Space>
                        </Card>
                    );
                }

                lastIndex = linkIndex + fileLink.length;
            }

            // 添加最后一段文本
            const afterText = sentence.slice(lastIndex);
            if (afterText.trim()) {
                elements.push(
                    <Text style={commonStyle} key={`text-last`}>
                        {afterText}
                    </Text>
                );
            }

            return <Space direction="vertical">{elements}</Space>;
        }
    };

    const isImage = (fileToken, fileName) => {
        // 首先检查文件名
        if (['.jpg', '.jpeg', '.png'].some(ext => fileName.toLowerCase().endsWith(ext))) {
            return true;
        }
        // 如果文件名是未知文件名，检查链接是否已经在 larkImageUrls 中并且链接包含图片扩展名
        if (fileName === '未知文件名' && larkImageUrls[fileToken]) {
            const url = larkImageUrls[fileToken];
            return ['.jpg', '.jpeg', '.png'].some(ext => url.toLowerCase().includes(ext));
        }
        return false;
    };

    useEffect(() => {
        messages
            .slice()
            .reverse()
            .forEach((msg) => {
                msg.content.forEach((item, contentIndx) => {
                    if (item && item.text && item.text.value) {
                        item.text.value
                            .split(/(?<![?？])([?？!。\n])/g)
                            .reduce((acc, curr, i, arr) => {
                                // 如果当前片段后面是?，且再后面以'开头，则组合它们
                                if (
                                    i % 2 === 0 &&
                                    arr[i + 1] === "?" &&
                                    arr[i + 2]?.startsWith("'")
                                ) {
                                    acc.push(curr + "?" + arr[i + 2]);
                                    return acc;
                                }
                                // 跳过已经被组合的片段
                                if (i % 2 === 0 && arr[i - 1] === "?" && curr.startsWith("'")) {
                                    return acc;
                                }
                                // 正常添加其他片段
                                if (i % 2 === 0) {
                                    acc.push(curr + (arr[i + 1] || ""));
                                }
                                return acc;
                            }, [])
                            .filter((sentence) => sentence.trim().length > 0) // 过滤掉空句子
                    }
                });
            });
    }, [messages]);

    const scrollToBottom = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: contentRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        //先获取助手线程
        const assistantId = selectedEmployee.id;
        const localAssistantId = localStorage.getItem("assistant_id");
        if (assistantId !== localAssistantId) {
            localStorage.setItem("assistant_id", assistantId);
        }

        let thread = JSON.parse(localStorage.getItem(`${assistantId}-thread_id`));
        if (thread) {
            setThreadId(thread);
            getMessageList(thread).then(() => {
                scrollToBottom(); // 初始加载时滚动到底部
            });
        } else {
            setMessages([]);
            newThreadHandler().then((threadId) => {
                scrollToBottom(); // 新建线程后滚动到底部
            });
        }
        // eslint-disable-next-line
    }, [selectedEmployee]);

    //控制气泡出现是否滑动
    useEffect(() => {
        if (!isUserScrolling) {
            scrollToBottom();
        }
    }, [messages, isUserScrolling]);

    // 获取消息列表，增加保护机制
    const getMessageList = async (threadId) => {
        try {
            const data = await openai.beta.threads.messages.list(threadId, {
                limit: 100,
            });
            // console.log(data)
            // 增加数据校验
            if (!data?.data) {
                console.log("获取消息列表失败: 数据格式不正确");
                return []; // 返回空数组作为默认值
            }

            const messages = data.data;

            // 获取最后一条消息
            const newLastMessage = messages[0];
            // 比较最后一条消息的 ID 是否相同
            if (lastMessage.current?.id === newLastMessage?.id) {
                return [];
            }

            // 获取图片内容并生成URL
            const imagePromises = messages.map(async (msg) => {
                if (!msg || !msg.content) return;

                // 遍历消息中的内容
                const contentPromises = msg.content.map(async (item) => {
                    if (
                        item.type === "image_file" &&
                        item.image_file &&
                        item.image_file.file_id
                    ) {
                        try {
                            const res = await openai.files.content(item.image_file.file_id);
                            const imageUrl = res.url;
                            if (imageUrl) {
                                // 更新图片的URL到状态中
                                setImageUrls((prev) => ({
                                    ...prev,
                                    [item.image_file.file_id]: imageUrl,
                                }));
                            }
                        } catch (error) {
                            console.error(
                                `获取图片内容失败: ${item.image_file.file_id}`,
                                error,
                            );
                        }
                    }
                });

                return Promise.all(contentPromises); // 等待所有内容异步操作完成
            });

            await Promise.all(imagePromises); // 确保所有图片获取操作都完成


            // 更新最后一条消息的引用和消息列表
            lastMessage.current = newLastMessage;
            setMessages(messages);
            return messages;
        } catch (error) {
            console.error("获取消息列表失败:", error);
            // Message.error("获取消息列表失败，请稍后再试。"); // 显示错误信息给用户
            return []; // 出错时返回空数组
        }
    };

    // 在对话新建时
    const newThreadHandler = async () => {
        const assistantId = localStorage.getItem("assistant_id");
        const emptyThread = await openai.beta.threads.create();
        setThreadId(emptyThread.id);
        localStorage.removeItem(`${assistantId}-thread_id`);
        localStorage.setItem(
            `${assistantId}-thread_id`,
            JSON.stringify(emptyThread.id),
        );
        setMessages([]);
        return emptyThread.id;
    };

    // 复制消息
    const handleCopy = (content) => {
        //复制当前内容
        navigator.clipboard.writeText(content);
    };

    // 删除消息
    const handleDeleteMessage = async (msg) => {
        setIsUserScrolling(true);
        try {
            if (!msg || !msg.id) {
                console.warn(`Message with ID ${msg.id} is not found.`);
                return;
            }

            if (msg.content[0].type === "image_file") {
                await openai.files.del(msg.content[0].image_file.file_id);
            }
            await openai.beta.threads.messages.del(threadId, msg.id);
            await getMessageList(threadId);
        } catch (error) {
            console.error("Error deleting message:", error);
        } finally {
            setIsUserScrolling(false);
        }
    };

    // 删除消息并刷新
    const handleRefreshMessage = async (msg, msgIndex) => {
        try {
            setIsUserScrolling(true);

            const endIndex = messages.length - 1 - msgIndex;
            const deletePromises = messages
                .slice(0, endIndex + 1)
                .flatMap((message) => {
                    const promises = [];
                    if (message.content[0].type === "image_file") {
                        promises.push(
                            openai.files.del(message.content[0].image_file.file_id),
                        );
                    }
                    if (message.id) {
                        promises.push(openai.beta.threads.messages.del(threadId, message.id));
                    } else {
                        console.warn('Message ID not found for deletion');
                    }
                    return promises;
                });

            await Promise.all(deletePromises);
            await getMessageList(threadId);

            setIsLoadingChat(true);
            await handleSend(threadId);
        } catch (error) {
            console.error("Error refreshing messages:", error);
            Message.error("刷新消息时发生错误，请稍后再试。");
        } finally {
            setIsUserScrolling(false);
            setIsLoadingChat(false);
        }
    };

    // 发送消息
    const handleSend = async (threadId) => {
        setIsUserScrolling(false);
        setInputValue("");
        setIsLoadingChat(true);

        try {
            if (inputValue.trim() !== "") {
                await openai.beta.threads.messages.create(threadId, {
                    role: "user",
                    content: inputValue,
                });
                await getMessageList(threadId);
            } else {
                if (messages.length > 1) {
                    if (messages[0].role === "assistant") {
                        if (messages[1].role === "user") {
                            await openai.beta.threads.messages.update(threadId, messages[1].id, {
                                metadata: {
                                    process: "false"
                                },
                            }).then(async () => {
                            });
                        }
                    } else {
                        await openai.beta.threads.messages.update(threadId, messages[0].id, {
                            metadata: {
                                process: "false"
                            }
                        }).then(async () => {
                        });
                    }
                }
                if (messages.length === 1) {
                    if (messages[0].role === "user") {
                        await openai.beta.threads.messages.update(threadId, messages[0].id, {
                            metadata: {
                                process: "false"
                            }
                        }).then(async () => {
                        });
                    }
                }
            }

            // 无论是否有输入，都执行以下操作
            setIsLoadingChat(true);
            await postRunByThreadBotCloud(
                threadId,
                "",
                salesMode.current,
                selectedEmployee.id,
                chatBackground,
            ).then(async (data) => {
                await handleBotResponse(
                    data,
                    threadId,
                    chatBackground,
                    handleBackgroundChange,
                );
            });
        } catch (error) {

            console.log("Error during message send:", error);
            console.error("Error during message send:", error);
            Message.error("发送消息时出错，请稍后再试。"); // 显示错误信息给用户
        } finally {
            // 无论成功与否，都取消加载状态
            setIsLoadingChat(false);
        }
    };

    const handleBotResponse = async (
        data,
        threadId,
        chatBackground,
        handleBackgroundChange,
    ) => {
        if (!data.result) {
            setIsLoadingChat(false);
            return false;
        }

        try {
            // 处理定时回复
            if (data.second) {
                await scheduleMessageReply(threadId, data.second);
            }

            if (data.new_background) {
                await handleBackgroundChange(data.new_background);
            } else if (data.memory) {
                const newBackground = chatBackground
                    ? chatBackground + "\n" + data.memory // 如果已有背景，添加换行符
                    : data.memory; // 如果没有背景，直接使用memory内容
                await handleBackgroundChange(newBackground);
            }

            await getMessageList(threadId);
            setIsLoadingChat(false);
            return true;
        } catch (processingError) {
            console.error("Error processing bot response:", processingError);
            Message.warning("处理响应时发生错误，但会话仍在继续");
            return false;
        }
    };

    // 清理定时器
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    //设置定时器
    const scheduleMessageReply = (threadId, seconds) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            console.log("定时器来了");
            getMessageList(threadId).then((r) => {
            });
            setIsLoadingChat(false);
        }, seconds * 1000);
    };

    // 上传图片
    const handleUploadPic = (currentFile) => {
        setFile({
            ...currentFile,
            url: URL.createObjectURL(currentFile.originFile),
        });
        setIsLoadingChat(true);

        if (currentFile.status === "uploading") {
            openai.files.create({
                file: currentFile.originFile,
                purpose: 'vision',
            }).then((data) => {
                openai.beta.threads.messages
                    .create(threadId, {
                        role: 'user',
                        content: [
                            {
                                type: 'image_file',
                                image_file: {
                                    file_id: data.id,
                                },
                            },
                        ],
                    })
                    .then(async () => {
                        await getMessageList(threadId).then((data) => {
                            setFile(null);
                            postRunByThreadBotCloud(
                                threadId,
                                draftInstructions,
                                salesMode.current,
                                selectedEmployee.id,
                                chatBackground,
                            ).then((data) => {
                                handleBotResponse(
                                    data,
                                    threadId,
                                    chatBackground,
                                    handleBackgroundChange,
                                ).then((r) => {
                                });
                            });
                        });
                    });
            });
        }
    };

    const handleDownloadFile = async (fileToken, fileName) => {
        try {
            const res = await getLarkTemporaryDownloadURLs([fileToken]);
            const tmpUrls = res.data.download_urls;
            if (tmpUrls.length > 0) {
                const downloadUrl = tmpUrls[0].tmp_download_url;
                console.log("下载链接", downloadUrl);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
            Message.error("下载文件时出错，请稍后再试。");
        }
    };

    // 实际msg为链接的渲染卡片
    const larkFileRegex = /(https:\/\/open\.feishu\.cn\/open-apis\/drive\/v1\/medias\/([^/]+)\/download)/g;

    //判断链接是哪种类型
    const isCard = (sentence, tableData) => {
        // 重置 lastIndex，确保从头开始匹配
        larkFileRegex.lastIndex = 0;

        // 如果包含任何飞书文件链接，就返回 "file"
        return larkFileRegex.test(sentence) ? "file" : "normal";
    };

    return (
        <Space
            direction="vertical"
            style={{
                height: "100vh",
                boxShadow: "-2px 0 2px rgba(0, 0, 0, 0.05)",
                paddingLeft: 10,
            }}
        >
            <Space
                style={{
                    width: "30vw",
                    height: 40,
                    marginTop: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h3>聊天测试</h3>
                <Tag color="green" icon={<IconWechat/>}>
                    <h3>{selectedEmployee.name}</h3>
                </Tag>
                <LaunchModal
                    selectedEmployee={selectedEmployee}
                    draftInstructions={roleInstructions}
                    chatMode={chatMode}
                    maxSeats={maxSeats}
                />
            </Space>

            <Space direction="vertical">
                <Space
                    direction="vertical"
                    style={{
                        width: "30vw",
                        height: "82vh",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                    }}
                    ref={contentRef}
                >
                    {messages.length === 0 ? (
                        <Empty
                            description="暂无聊天数据"
                            style={{
                                marginTop: "30vh",
                            }}
                        />
                    ) : (
                        messages
                            .slice()
                            .reverse()
                            .map((msg, index) => {
                                return (
                                    <Space key={index} align={"start"}>
                                        <Avatar>
                                            <img
                                                alt="avatar"
                                                src={
                                                    msg.role === "user"
                                                        ? "https://pic1.zhimg.com/70/v2-53c1bc420d925bbc55d3ebddc1f1a091_1440w.avis?source=172ae18b&biz_tag=Post"
                                                        : "https://pic1.zhimg.com/70/v2-73543239e7ae941adf7d3a4d62385951_1440w.avis?source=172ae18b&biz_tag=Post"
                                                }
                                            />
                                        </Avatar>
                                        <Space direction={"vertical"}>
                                            <Space direction="horizontal">
                                                <Text>{msg.role === "user" ? "用户" : "销售"}</Text>
                                                <Text type="secondary" style={{fontSize: "12px"}}>
                                                    {formatTimestampToDateTime(msg.created_at)}
                                                </Text>
                                                <Text style={{fontSize: "12px", color: "#366ef4"}}>
                                                    {msg.role === "assistant" &&
                                                        index > 0 &&
                                                        `${Math.abs(msg.created_at - msg.metadata.created_time)}s`}
                                                </Text>
                                                {msg.role === "user" && msg.metadata.manual && (
                                                    <Tag color={"red"} size={"small"}>
                                                        触发人工
                                                    </Tag>
                                                )}
                                            </Space>
                                            <Space direction={"vertical"}>
                                                {msg.content.map((item, contentIndex) => {
                                                    if (
                                                        item.type === "text" &&
                                                        item.text &&
                                                        item.text.value
                                                    ) {
                                                        return (
                                                            <React.Fragment key={`${msg.id}-${contentIndex}`}>
                                                                {item.text.value
                                                                    .split(/(?<![?？])([?？!。\n])/g)
                                                                    .reduce((acc, curr, i, arr) => {
                                                                        // 如果当前片段后面是?，且再后面以'开头，则组合它们
                                                                        if (
                                                                            i % 2 === 0 &&
                                                                            arr[i + 1] === "?" &&
                                                                            arr[i + 2]?.startsWith("'")
                                                                        ) {
                                                                            acc.push(curr + "?" + arr[i + 2]);
                                                                            return acc;
                                                                        }
                                                                        // 跳过已经被组合的片段
                                                                        if (
                                                                            i % 2 === 0 &&
                                                                            arr[i - 1] === "?" &&
                                                                            curr.startsWith("'")
                                                                        ) {
                                                                            return acc;
                                                                        }
                                                                        // 正常添加其他片段
                                                                        if (i % 2 === 0) {
                                                                            acc.push(curr + (arr[i + 1] || ""));
                                                                        }
                                                                        return acc;
                                                                    }, [])
                                                                    .filter(
                                                                        (sentence) => sentence.trim().length > 0,
                                                                    ) // 过滤掉空句子
                                                                    .map((sentence, sentenceIndex) => {
                                                                        const key = isCard(sentence, tableData); // 传递 tableData
                                                                        const renderComponent = msgMap[key];
                                                                        if (renderComponent)
                                                                            return (
                                                                                <Space
                                                                                    key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                                                                    align={"center"}
                                                                                >
                                                                                    {
                                                                                        renderComponent(sentence, tableData, msg)
                                                                                    }
                                                                                    <Space>
                                                                                        {msg.role !== "user" && (
                                                                                            <Button
                                                                                                size="mini"
                                                                                                icon={<IconCopy/>}
                                                                                                onClick={() =>
                                                                                                    handleCopy(
                                                                                                        msg.role === "user"
                                                                                                            ? sentence
                                                                                                            : sentence.replace(
                                                                                                                /[？?！!。，,；;、]+$/g,
                                                                                                                "",
                                                                                                            ),
                                                                                                    )
                                                                                                }
                                                                                                type="text"
                                                                                                style={{color: "black"}}
                                                                                            />
                                                                                        )}
                                                                                        {/* 如果是最后一个句子且是助手的消息才显示按钮 */}
                                                                                        {sentenceIndex === 0 && (
                                                                                            <Space>
                                                                                                <Button
                                                                                                    size="mini"
                                                                                                    icon={<IconDelete/>}
                                                                                                    onClick={() =>
                                                                                                        handleDeleteMessage(msg)
                                                                                                    }
                                                                                                    type="text"
                                                                                                    style={{color: "black"}}
                                                                                                />
                                                                                                {msg.role !== "user" && (
                                                                                                    <Tooltip
                                                                                                        content="重新回答">
                                                                                                        <Button
                                                                                                            size="mini"
                                                                                                            icon={
                                                                                                                <IconRefresh/>}
                                                                                                            onClick={() =>
                                                                                                                handleRefreshMessage(
                                                                                                                    msg,
                                                                                                                    index,
                                                                                                                )
                                                                                                            }
                                                                                                            type="text"
                                                                                                            style={{color: "black"}}
                                                                                                        />
                                                                                                    </Tooltip>
                                                                                                )}
                                                                                            </Space>
                                                                                        )}
                                                                                    </Space>
                                                                                </Space>
                                                                            );
                                                                        return null;
                                                                    })}
                                                            </React.Fragment>
                                                        );
                                                    } else if (
                                                        item.type === "image_file" &&
                                                        imageUrls[item.image_file.file_id]
                                                    ) {
                                                        return (
                                                            <Space key={item.image_file.file_id}>
                                                                <Image
                                                                    src={imageUrls[item.image_file.file_id]}
                                                                    alt="用户图片"
                                                                    width={250}
                                                                    style={{height: "auto"}}
                                                                />
                                                                <Button
                                                                    size="mini"
                                                                    icon={<IconDelete/>}
                                                                    onClick={() => handleDeleteMessage(msg)}
                                                                    type="text"
                                                                    style={{color: "black"}}
                                                                ></Button>
                                                            </Space>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Space>
                                        </Space>
                                    </Space>
                                );
                            })
                    )}
                    {isLoadingChat && <Spin dot style={{marginLeft: "50px"}}/>}
                </Space>

                <Space style={{marginTop: "2vh", paddingLeft: 4}}>
                    <Tooltip content="清空对话记录">
                        <Button
                            type="text"
                            shape="circle"
                            onClick={newThreadHandler}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                >
                                    <path
                                        d="M2 6h4V2.333C6 1.597 6.597 1 7.334 1h1.333C9.403 1 10 1.597 10 2.333V6h4c.737 0 1.334.597 1.334 1.333V14c0 .736-.597 1.333-1.334 1.333H2A1.333 1.333 0 0 1 .667 14V7.333C.667 6.597 1.264 6 2 6Zm5.334-3.667v4a1 1 0 0 1-1 1H2v1.334h12V7.333H9.667a1 1 0 0 1-1-1v-4H7.334ZM2 10v4h2.667v-1.667a.667.667 0 0 1 1.333 0V14h1.334v-2a.667.667 0 1 1 1.333 0v2H10v-1.667a.667.667 0 0 1 1.334 0V14H14v-4H2Z"/>
                                </svg>
                            }
                        />
                    </Tooltip>
                    <Input
                        style={{
                            height: "5vh",
                            width: "calc(30vw - 100px)",
                            marginLeft: 4,
                        }}
                        disabled={disableValue.current}
                        value={inputValue}
                        onChange={(value) => setInputValue(value)}
                        onPressEnter={() => handleSend(threadId)}
                        suffix={
                            <Upload
                                showUploadList={false}
                                fileList={file ? [file] : []}
                                multiple={false}
                                onChange={(_, currentFile) => handleUploadPic(currentFile)}
                                accept=".png,.jpeg,.jpg"
                            >
                                <Button
                                    type="text"
                                    size={"mini"}
                                    icon={<IconFileImage/>}
                                    style={{color: "black"}}
                                ></Button>
                            </Upload>
                        }
                    />

                    <Tooltip content="发送聊天">
                        <Button
                            type={inputValue === "" ? "text" : "text"}
                            icon={
                                <IconSend
                                    style={{
                                        width: "20",
                                        height: "20",
                                        marginLeft: 10,
                                        marginTop: 3,
                                    }}
                                />
                            }
                            onClick={() => handleSend(threadId)} // 传入当前的 threadId
                            disabled={inputValue === ""}
                        />
                    </Tooltip>
                </Space>
            </Space>
        </Space>
    );
};
export default ChatTest;
